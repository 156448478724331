import type React from "react";

export type AnchorPointType = {
	id: string;
	title: string;
	type: string;
};

export interface AnchorPointProps extends Pick<AnchorPointType, "id"> {}

/**
  In page marker to be used before content sections.

  Pairs with `AnchorTabs`.
 */
export const AnchorPoint: React.FC<
	React.PropsWithChildren<AnchorPointProps>
> = ({ id }) => (
	<div id={id} sx={{ scrollMarginTop: ({ space }) => space["4xl"] }} />
);
